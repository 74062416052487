import BasePopup from "../popups/BasePopup";
import Rules from "./Validation";
import DateHelper from "./DateHelper";

export default {
    components: {BasePopup},
    mixins: [Rules, DateHelper],
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        item: {
            type: Object,
            default: () => ({})
        },
        basicData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            valid: true,
            isUser: false,
            isImportCsv: false,
            validationError: '',
            formRef: 'formRef',
            fileUrl: {
                imageUrl: null,
                fileUrl: null
            },
            error: {
                imageError: null,
                fileError: null
            },
            templateDetails: [
                {
                    subject: '',
                    description: '',
                }
            ]
        }
    },

    computed: {
        isEditMode() {
            return this.item && Object.keys(this.item).length > 0;
        },
        isFormValid() {
            return this.$refs.formRef ? this.$refs.formRef.validate() : true;
        },
        currentItem() {
            if (!this.show) {
                return {};
            }

            if (this.isEditMode) {
                if(this.item.template_details && this.item.template_details.length > 0) {
                    this.templateDetails = this.item.template_details;
                }

                return {...this.item};
            }

            return {...this.basicData}
        },
        formRefPopUp: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit('onPopupShow', val);
            },
        }
    },
    methods: {
        closePopupHandler() {
            this.$refs.formRef.reset();
            this.templateDetails = [
                {
                    description: ''
                }
            ];

            this.liveLinks = [];
            this.link = '';
            this.siteError = '';
            this.isImportCsv = false;
            this.fileUrl= {
                imageUrl: null,
                fileUrl: null
            }
            this.error = {
                imageError: null,
                fileError: null
            }
            this.$emit('closePopupHandler', true);
        },
        saveItemHandler() {
            if (!this.isFormValid) {
                return;
            }

            this.$emit('saveItemHandler', {...this.currentItem});
            this.closePopupHandler();
        },
        onSelections(name, selection) {
            this.currentItem[name] = selection.id;
        },
        handleClearSearchResult(name) {
            this.currentItem[name] = '';
        },
        onChangeImageHandler(file) {
            const me = this;
            //const file = e.target.files[0];
            this.error.imageError = null;
            if (file && file.type.match('image/*')) {
                if (file.size > 1024 * 1024) {
                    this.error.imageError = 'Image too big (> 1MB)';
                    return;
                }
                this.getBase64(file, function (base64Data) {
                    me.currentItem.attachment = base64Data;
                    me.$forceUpdate();
                });
                this.fileUrl.imageUrl = URL.createObjectURL(file);
            } else {
                this.error.imageError = 'Only image files are allowed!';
            }
        },
        onChangeFileHandler(e) {
            const me = this;
            const file = e.target.files[0];
            this.error.fileError = null;
            if (file && (file.type.match('image/*')
                || file.type.match('application/pdf')
                || file.type.match('application/msword')
                || file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document'))
            ) {
                if (file.size > 1024 * 1024) {
                    this.error.fileError = 'File too big (> 1MB)';
                    return;
                }
                this.getBase64(file, function (base64Data) {
                    me.currentItem.file = base64Data;
                });
                this.fileUrl.fileUrl = URL.createObjectURL(file);
            } else {
                this.error.fileError = 'Only image, doc and pdf are allowed!';
            }
        },
        getBase64(file, callback) {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(file);
        },
        onClearHandler() {
            this.currentItem.attachment = '';
            this.$forceUpdate();
            this.fileUrl = {
                imageUrl: '',
                fileUrl: ''
            }

            this.error = {
                imageError: '',
                fileError: ''
            }
        },
        download(dataUrl, name) {
            const linkSource = `${dataUrl}`;
            const downloadLink = document.createElement("a");
            const fileName = name ? name.replace(' ', '_') : 'employee';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        csvHandler(event) {
            this.currentItem.mailCsv = event.target.files[0];
        },
        onClearCsvHandler() {
            this.currentItem.mailCsv = '';
        },
        countrySelectionHandler(item){
            this.currentItem.country=item;
        }
    }
};
